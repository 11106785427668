import * as d3 from "d3";
import { useEffect, useRef } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";

const PoemCountBar = (props) => {
  const svgRef = useRef();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const { anthologyKey, data, dataFilter, dimension } = props;

  const filterAnthology = Object.keys(anthologyKey).filter(
    (a) => dataFilter[a]
  );

  useEffect(() => {
    const svg = d3.select(svgRef.current);
    svg.selectAll("*").remove();

    const margin = { top: 20, right: 0, bottom: 120, left: 0 };
    const width =
      (matches ? dimension.width * 0.6 : dimension.width) -
      margin.left -
      margin.right;
    const height = dimension.height - margin.top - margin.bottom;

    const chartSvg = svg
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    const x = d3
      .scaleBand()
      .range([0, width])
      .domain(filterAnthology)
      .padding(0.2);

    chartSvg
      .append("g")
      .attr("transform", `translate(0, ${height})`)
      .call(d3.axisBottom(x))
      .selectAll("text")
      .attr("transform", "translate(-12.5,8)rotate(-90)")
      .style("text-anchor", "end");

    const y = d3
      .scaleLinear()
      .domain([0, Math.max(...filterAnthology.map((a) => data.data[a])) * 1.1])
      .range([height, 0]);

    chartSvg
      .selectAll("mybar")
      .data(filterAnthology)
      .join("rect")
      .attr("x", (d) => x(d))
      .attr("y", (d) => y(data.data[d]))
      .attr("width", x.bandwidth())
      .attr("height", (d) => height - y(data.data[d]))
      .attr("fill", "#69b3a2");

    chartSvg
      .selectAll(".label")
      .data(filterAnthology)
      .join("g")
      .append("text")
      .attr(
        "transform",
        (d) => `translate(${x(d) + x.bandwidth() / 2}, ${y(data.data[d]) - 6})`
      )
      // .attr("y", 12)
      .text((d) => data.data[d])
      .style("font-size", 11)
      .style("font-weight", 600)
      .style("text-align", "center")
      .style("text-anchor", "middle")
      .style("fill", "#000");
  }, [
    props,
    anthologyKey,
    data,
    dimension,
    dataFilter,
    filterAnthology,
    matches,
  ]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        columnGap: "1.5em",
        rowGap: "1em",
      }}
    >
      <svg ref={svgRef} />
      <TableContainer
        component={Paper}
        sx={{
          height: "fit-content",
          width: { xs: dimension.width, md: dimension.width * 0.3 },
          backgroundColor: "#87810008",
        }}
      >
        <Table>
          <TableBody>
            {filterAnthology.map((a) => (
              <TableRow
                key={a}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {a}
                </TableCell>
                <TableCell align="right">{anthologyKey[a]}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default PoemCountBar;
